import React from 'react';
import { FormattedMessage } from 'react-intl';

import { CheckMarkHotJobBadgeContainer } from './CheckMarkHotJobBadge.sc';

const CheckMarkHotJobBadge = () => (
  <CheckMarkHotJobBadgeContainer>
    <FormattedMessage tagName="span" id="hotjob" defaultMessage="Hot" />
  </CheckMarkHotJobBadgeContainer>
);

export default CheckMarkHotJobBadge;
