import { isUndefined } from 'lodash';

import { ExperienceValueLabel } from '../enums/jobs';
import { ExperienceMessageMap } from '../messages/jobs';

export const yearsOfExperienceToExperienceValueLabel = (
  min: number,
  max: number
) => {
  if (min === 0 && max === 1) return ExperienceValueLabel.LessThanOneYear;
  if (min === 1 && max === 3) return ExperienceValueLabel.OneToThreeYears;
  if (min === 3 && max === 5) return ExperienceValueLabel.ThreeToFiveYears;
  if (min === 5 && max === 10) return ExperienceValueLabel.FiveToTenYears;
  if (min === 0 && max === 50) return ExperienceValueLabel.MoreThanTenYears;
  if (min === 10 && max === 50) return ExperienceValueLabel.MoreThanTenYears;
  return ExperienceValueLabel.LessThanOneYear;
};

export const getYearsOfExperienceMessage = (min: number, max: number) =>
  !isUndefined(min) && !isUndefined(max) && (min !== 0 || max !== 50)
    ? ExperienceMessageMap[yearsOfExperienceToExperienceValueLabel(min, max)]
    : null;
