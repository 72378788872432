import { RefObject, useEffect, useState } from 'react';

// Reference: https://usehooks.com/useOnScreen/
export const useOnScreen = <T extends HTMLDivElement>(
  ref: RefObject<T>
): boolean => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  useEffect(function checkIfCurrentElementIsOnScreen() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!ref) return () => {};

    const currentElement = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry) setIntersecting(entry.isIntersecting);
      },
      {
        threshold: 1,
      }
    );
    if (currentElement) {
      observer.observe(currentElement);
    }
    return () => {
      if (currentElement) observer.unobserve(currentElement);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isIntersecting;
};
