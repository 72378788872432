import { JobInterface } from 'src/modules/Opportunity/interface';

export const getJobCardInfo = (job: JobInterface) => {
  const {
    minYearsOfExperience,
    maxYearsOfExperience,
    salaries,
    salaryEstimate,
    company,
    skills,
  } = job;

  const jobCardInfo: string[] = [];

  if ((minYearsOfExperience === 0 && maxYearsOfExperience === 50) === false) {
    jobCardInfo.push('experience');
  }

  if (salaries && salaries.length > 0) {
    jobCardInfo.push('salary');
  }

  if (salaryEstimate) {
    jobCardInfo.push('Glints salary estimate');
  }

  if (company && company.logo) {
    jobCardInfo.push('logo');
  }

  if (skills) {
    const skillNames = skills.map((jobSkill) => jobSkill.skill.name).join(',');
    if (skillNames.length > 0) {
      jobCardInfo.push(skillNames);
    }
  }

  return jobCardInfo;
};
