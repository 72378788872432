import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ImpressionBatchContext } from 'src/common/contexts/ImpressionBatchContext';
import { JobsSortByKey } from 'src/common/enums/jobs';
import { useOnScreen } from 'src/common/hooks/useOnScreen';

import {
  addImpressionAction,
  clearImpressionAction,
} from '../TrackImpressionsBatch/TrackImpressionsBatch';

type Props = ImpressionEvent & { children: ReactElement };

export type ImpressionEvent = {
  jobId: string;
  isHot: boolean;
  jobIndexPosition: number;
  searchedKeywords?: string;
  searchedLocation?: string;
  companyId: string;
  jobCardInfo: string;
  jobCategory: string;
  jobSubCategory: string;
  jobRole: string;
  sortBy: JobsSortByKey;
  hasSkills?: boolean;
  source?: string;
};

export const TrackImpressions: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const {
    jobId,
    isHot,
    jobIndexPosition,
    searchedKeywords,
    searchedLocation,
    companyId,
    jobCardInfo,
    jobCategory,
    jobSubCategory,
    jobRole,
    sortBy,
    hasSkills,
    source,
  } = props;

  const impressionDispatch = useContext(ImpressionBatchContext);

  const ref = useRef<HTMLInputElement>(null);
  const [impressed, setImpressed] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(
    function clearPendingImpressionsWhenSearchChanged() {
      impressionDispatch(clearImpressionAction());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedKeywords, searchedLocation]
  );

  useEffect(
    function updateImpressionEventWhenElementIsVisibleToUser() {
      if (!impressed && onScreen) {
        const event: ImpressionEvent = {
          jobId,
          isHot,
          jobIndexPosition,
          searchedKeywords,
          searchedLocation,
          companyId,
          jobCardInfo,
          jobCategory,
          jobSubCategory,
          jobRole,
          sortBy,
          hasSkills,
          source,
        };
        setImpressed(true);
        impressionDispatch(addImpressionAction(event));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onScreen]
  );

  return React.cloneElement(React.Children.only(props.children), { ref });
};
