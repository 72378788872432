import { gql } from '@apollo/client';

import { Experience } from 'src/global/models';

export type Skill = {
  __typename?: 'Skill';
  id: string;
  isNormalized?: boolean;
  isCustom?: boolean;
  name: string;
};

export type Me = {
  __typename?: 'Me';
  skills?: Array<Skill>;
  certificates?: Array<Experience>;
  whatsappLoginNumber: string | null;
};

export type GetMeQuery = {
  __typename?: 'Query';
  getMe: Me;
};

export const getMeQuery = gql`
  query getMe {
    getMe {
      skills {
        id
        name
        isNormalized
        isCustom
      }
      certificates {
        id
        type
        title
        organization
        notes
        startDate
        endDate
        isPresent
      }
      whatsappLoginNumber
    }
  }
`;
