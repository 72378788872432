import React from 'react';
import { Icon } from 'glints-aries/lib/@next';
import { Neutral } from 'glints-aries/lib/@next/utilities/colors';
import { identity } from 'lodash';
import Link from 'next/link';
import styled from 'styled-components';

import { useResponsiveOpenTargetAttribute } from 'src/common/hooks/useResponsiveOpenTargetAttribute';
import { getSlugsURLParameter } from 'src/common/utils/hierarchicalLocationHelpers';
import { City } from 'src/components/City';
import { JobInterface } from 'src/modules/Opportunity/interface';
import { HierarchicalLocation } from 'src/modules/Profile/graphql/hierarchicalLocations';

import * as Styles from './CompactOpportunityCard.sc';
import { JobCardNoStyleAnchor } from './CompactOpportunityCard.sc';

const LocationWrapper = styled.div`
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LocationSpan = styled.span`
  white-space: nowrap;
`;

type Props = {
  job: JobInterface;
};

const CardJobLocation = ({ job }: Props) => {
  const cityName = job.city.name;
  const countryName = job.country.name;
  const citySubDivisionName = job.citySubDivision?.name;
  const location = job.location as HierarchicalLocation;

  const target = useResponsiveOpenTargetAttribute();

  if (location) {
    const locationItems = getLocationItems(location, target);
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} />
        <LocationWrapper>{locationItems}</LocationWrapper>
      </Styles.CompanyInformation>
    );
  }

  if (cityName) {
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} width={12} height={12} />
        <span>
          {[citySubDivisionName, cityName].filter(identity).join(', ')}
        </span>
      </Styles.CompanyInformation>
    );
  }
  if (job.CityId) {
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} width={12} height={12} />
        <City id={job.CityId} />
      </Styles.CompanyInformation>
    );
  } else if (countryName) {
    return (
      <Styles.CompanyInformation>
        <Icon name="ri-map-line" fill={Neutral.B40} width={12} height={12} />
        <span>{countryName}</span>
      </Styles.CompanyInformation>
    );
  }
  return null;
};

const getLocationItems = (location: HierarchicalLocation, target?: string) => {
  const parents = [...(location.parents ?? [])]
    .sort((a, b) => b.level - a.level)
    .filter((item) => item.level > 1);

  const locations = [location, ...parents].filter(identity);

  return locations.map((item, index) => {
    const isLastOne = index === locations.length - 1;
    const slugs = getSlugsURLParameter(item);
    return (
      <LocationSpan key={item.id} title={item.formattedName}>
        <Link href={`/job-location/${slugs}`} passHref={true}>
          <JobCardNoStyleAnchor
            target={target}
            onClick={(e) => e.stopPropagation()}
          >
            {item.formattedName}
          </JobCardNoStyleAnchor>
        </Link>
        {!isLastOne && <LocationSpan>, </LocationSpan>}
      </LocationSpan>
    );
  });
};

export default CardJobLocation;
