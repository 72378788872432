import { get } from 'lodash';
import { createSelector, defaultMemoize } from 'reselect';

import { City } from 'src/global/models';
import { State } from 'src/global/store';

import { getCities } from './api';

const getCitiesState = (state: State) => state.cities;
const getCurrentCitiesIds = (state: State) => getCitiesState(state).value;

export const getIsLoadingCities = (state: State) =>
  getCitiesState(state).loading;

export const getCurrentCities = createSelector(
  [getCurrentCitiesIds, getCities],
  (ids, cities) => ids.map((id) => cities[id])
);

export const buildCityCountry = defaultMemoize((city: City) => ({
  ...city,
  label: get(city, 'links.country.name')
    ? `${city.name}, ${city.links.country.name}`
    : city.name,
}));

export const buildCurrentCityCountryList = createSelector(
  getCurrentCities,
  (cities) => cities.map(buildCityCountry)
);

export const buildCityCountryList = defaultMemoize((cities: City[]) =>
  cities.map(buildCityCountry)
);

export const getCity = (state: State, id: number) => getCities(state)[id];
