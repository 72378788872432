import { Colors } from 'glints-aries/lib/@next';
import styled from 'styled-components';

export const CheckMarkHotJobBadgeContainer = styled.div`
  white-space: nowrap;
  background-color: ${Colors.Orange.S87};
  color: white;
  display: flex;

  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;

  user-select: none;
  text-transform: uppercase;
  span {
    font-weight: 700;
  }
`;
