import React from 'react';
import moment, { Moment } from 'moment';
import { FormattedMessage } from 'react-intl';

export const wasPostedOrUpdatedRecently = (then: string) => {
  return moment(then).isAfter(moment().startOf('day').subtract(1, 'days'));
};

export const TimeAgo: React.FC<React.PropsWithChildren<{ time: string }>> = ({
  time,
}) => {
  const then = moment(time);
  const startOfToday = moment().startOf('day');

  const getTimeType = (then: Moment) => {
    if (then.isBefore(startOfToday.clone().subtract(1, 'year'))) return 'year';
    else if (then.isBefore(startOfToday.clone().subtract(1, 'month')))
      return 'month';
    else if (then.isBefore(startOfToday.clone())) return 'day';
    else if (then.isBefore(moment().subtract(1, 'hour'))) return 'hour';
    else if (then.isBefore(moment().subtract(1, 'minute'))) return 'minute';
    return 'second';
  };

  const getTimeAgo = (num: number, timeType: string) => {
    switch (timeType) {
      case 'year':
        return (
          <FormattedMessage
            id="time-ago.year"
            defaultMessage={'{num, plural, =1 {1 year} other {# years}} ago'}
            values={{ num }}
          />
        );
      case 'month':
        return (
          <FormattedMessage
            id="time-ago.month"
            defaultMessage={'{num, plural, =1 {1 month} other {# months}} ago'}
            values={{ num }}
          />
        );
      case 'day':
        return (
          <FormattedMessage
            id="time-ago.day"
            defaultMessage={'{num, plural, =1 {yesterday} other {# days ago}}'}
            values={{ num: num + 1 }}
          />
        );
      case 'hour':
        return (
          <FormattedMessage
            id="time-ago.hour"
            defaultMessage={'{num, plural, =1 {1 hour} other {# hours}} ago'}
            values={{ num }}
          />
        );
      case 'minute':
        return (
          <FormattedMessage
            id="time-ago.minute"
            defaultMessage={
              '{num, plural, =1 {1 minute} other {# minutes}} ago'
            }
            values={{ num }}
          />
        );
      default:
        return (
          <FormattedMessage
            id="time-ago.second"
            defaultMessage={
              '{num, plural, =1 {1 second} other {# seconds}} ago'
            }
            values={{ num }}
          />
        );
    }
  };

  const getTimeAgoNum = (
    timeType: moment.unitOfTime.DurationConstructor,
    then: Moment
  ) => {
    return timeType === 'hour' || timeType === 'minute' || timeType === 'second'
      ? moment().diff(then, timeType)
      : startOfToday.clone().diff(then, timeType);
  };

  const timeType = getTimeType(then);
  const timeAgoNum = getTimeAgoNum(timeType, then);
  const timeAgo = getTimeAgo(timeAgoNum, timeType);

  return timeAgo;
};
