import { WorkArrangementOption } from 'src/global/models/Job';

type EntityLokalise = {
  lokaliseId: string;
  lokaliseDefaultMessage: string;
};

const LokaliseRemoteMessage: EntityLokalise = {
  lokaliseId: 'label-remote',
  lokaliseDefaultMessage: 'Remote/WFH',
};
const LokaliseOnsiteMessage: EntityLokalise = {
  lokaliseId: 'label-on-site',
  lokaliseDefaultMessage: 'On-site',
};
const LokaliseHybridMessage: EntityLokalise = {
  lokaliseId: 'label-hybrid',
  lokaliseDefaultMessage: 'Hybrid',
};

export class OpportunityHelper {
  static getWorkArrangementOptionLokalise = (
    workArrangementOption?: WorkArrangementOption
  ): EntityLokalise => {
    switch (workArrangementOption) {
      case WorkArrangementOption.ONSITE:
        return LokaliseOnsiteMessage;
      case WorkArrangementOption.REMOTE:
        return LokaliseRemoteMessage;
      case WorkArrangementOption.HYBRID:
        return LokaliseHybridMessage;
      default:
        return LokaliseOnsiteMessage;
    }
  };
}
