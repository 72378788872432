import { Tag as AriesTag } from 'glints-aries/lib/@next';
import { Orange } from 'glints-aries/lib/@next/utilities/colors';
import styled from 'styled-components';

export const Tag = styled(AriesTag)`
  max-width: 150px;
  span {
    width: 100%;
    div {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        display: block;
        max-width: 150px;
      }
    }
  }
`;

export const VIPTag = styled(Tag)`
  color: ${Orange.S100};

  span {
    background-color: ${Orange.S21};
  }
`;
