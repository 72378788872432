import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImpressionBatchContext } from 'src/common/contexts/ImpressionBatchContext';
import {
  GTMJobCardBatchVisibleImpression,
  GTMJobCardVisibleImpression,
} from 'src/modules/Opportunities/Actions';
import { ImpressionEvent } from 'src/modules/Opportunities/components/TrackImpressions/TrackImpressions';
import { getUser } from 'src/selectors/user';

const BATCH_SIZE = 10;

export enum ImpressionActionType {
  ADD_IMPRESSION,
  CLEAR_IMPRESSION,
}

type ImpressionBatchEvent = {
  jobId: Array<string>;
  jobIndexPosition: Array<number>;
  searchKeyword: string;
  searchLocation: string;
};

type Action = {
  type: ImpressionActionType;
  payload?: {
    jobId: string;
    jobIndex: number;
    currentEvent?: ImpressionEvent;
  };
};

export function addImpressionAction(event: ImpressionEvent): Action {
  return {
    type: ImpressionActionType.ADD_IMPRESSION,
    payload: {
      jobId: event.jobId,
      jobIndex: event.jobIndexPosition,
      currentEvent: event,
    },
  };
}
export function clearImpressionAction(): Action {
  return {
    type: ImpressionActionType.CLEAR_IMPRESSION,
  };
}

function impressionsReducer(
  state: {
    ids: Array<string>;
    idxs: Array<number>;
    currentEvent: ImpressionEvent;
  },
  { type, payload }: Action
) {
  switch (type) {
    case ImpressionActionType.ADD_IMPRESSION:
      return {
        ...state,
        ids: [...state.ids, payload?.jobId],
        idxs: [...state.idxs, payload?.jobIndex],
        currentEvent: payload.currentEvent,
      };
    case ImpressionActionType.CLEAR_IMPRESSION:
      return {
        ids: [],
        idxs: [],
        currentEvent: null,
      };
    default:
      return state;
  }
}

export const TrackImpressionsBatch: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const batchCount = useRef(0);
  const [impressions, dispatch] = useReducer(impressionsReducer, {
    ids: [],
    idxs: [],
    currentEvent: null,
  });

  const reduxDispatch = useDispatch();
  const { id: userId } = useSelector(getUser);

  const isLoggedIn = Boolean(userId);

  const onSendImpressionsBatchEvent = (trackEvent: ImpressionBatchEvent) => {
    reduxDispatch(GTMJobCardBatchVisibleImpression(trackEvent));
  };

  const onSendImpressionsEvent = (trackEvent: ImpressionEvent) => {
    reduxDispatch(GTMJobCardVisibleImpression(trackEvent));
  };

  useEffect(
    function trackImpressionEvents() {
      if (isLoggedIn && impressions.ids.length > 0) {
        if (batchCount.current < 1 && impressions.currentEvent) {
          onSendImpressionsEvent(impressions.currentEvent);
        }
        if (impressions.ids.length % BATCH_SIZE === 0) {
          if (batchCount.current >= 1) {
            onSendImpressionsBatchEvent({
              jobId: impressions.ids,
              jobIndexPosition: impressions.idxs,
              searchKeyword: impressions.currentEvent.searchedKeywords,
              searchLocation: impressions.currentEvent.searchedLocation,
            });
          }
          batchCount.current += 1;
          dispatch(clearImpressionAction());
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [impressions.ids.length]
  );

  return (
    <ImpressionBatchContext.Provider value={dispatch}>
      {children}
    </ImpressionBatchContext.Provider>
  );
};
