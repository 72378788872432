import { createAction } from 'redux-actions';

const GTMActions = {
  JOB_CARD_VISIBLE: 'glints/GoogleTagManager/JOB_CARD_VISIBLE',
  JOB_CARD_BATCH_VISIBLE: 'glints/GoogleTagManager/JOB_CARD_BATCH_VISIBLE',
};

export const GTMJobCardVisibleImpression = createAction(
  GTMActions.JOB_CARD_VISIBLE
);

export const GTMJobCardBatchVisibleImpression = createAction(
  GTMActions.JOB_CARD_BATCH_VISIBLE
);
