import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCities } from '../../actions/cities';
import { getCity } from '../../selectors/cities';

// To cache the city ids that are being fetched
let cityIdsBeingFetched: number[] = [];

const _City: React.FC<React.PropsWithChildren<{ id: number }>> = ({ id }) => {
  const dispatch = useDispatch();
  const city = useSelector((state) => getCity(state, id));

  useEffect(
    function fetchUncachedCity() {
      const shouldFetchedCity =
        id && !city && !cityIdsBeingFetched.find((cid) => cid === id);

      if (shouldFetchedCity) {
        cityIdsBeingFetched.push(id);
        dispatch(fetchCities({ params: { where: { id: id } } }, true));
      }

      return () => {
        cityIdsBeingFetched = [];
      };
    },
    [city, dispatch, id]
  );

  if (!city) {
    return null;
  }

  return <>{city.name}</>;
};

export const City = React.memo(_City);
